<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              <span>单据编号：</span>
              <el-input
                v-model="parameter.expenseNumber"
                class="ipt_width expense__width"
                placeholder="请输入单据编号"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              <span> 商务名称：</span>
              <el-select
                v-model="parameter.businessManageId"
                clearable
                filterable
                placeholder="请选择商务名"
                class="ipt_width project_width"
              >
                <el-option
                  v-for="item in BusinessList"
                  :key="item.id"
                  :label="item.businessName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="search_center">
              <span>收款单位：</span>
              <el-input
                v-model="parameter.collectionUnit"
                class="ipt_width expense__width"
                placeholder="请输入收款单位"
                clearable
              >
              </el-input>
            </div>

            <div class="search_center">
              <span>申请时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="dialogVisible = true">申请流程</el-button>
          <el-button type="warning" icon="el-icon-plus" @click="handleAdd">新增</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.applyType" class="fullScreenMainHeader">
            <el-tab-pane name="UNDETERMINED">
              <span slot="label">未提交 {{ `(${this.statistics.undetermind})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL">
              <span slot="label">审批中 {{ `(${this.statistics.approval})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL_REJECT">
              <span slot="label">不通过 {{ `(${this.statistics.approvalReject})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="NOT_PAYED">
              <span slot="label">已审核 {{ `(${this.statistics.notPayed})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL_PASS">
              <span slot="label">已支付 {{ `(${this.statistics.approvalPass})` }}</span>
            </el-tab-pane>
          </el-tabs>
          <el-table
            v-loading="loading"
            :data="tableData"
            ref="multipleTable"
            tooltip-effect="dark"
            height="string"
            border
          >
            <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
            <el-table-column
              prop="expenseNumber"
              label="单据编号"
              align="center"
              width="175"
            ></el-table-column>
            <el-table-column
              prop="businessName"
              sortable
              label="商务名称"
              :show-overflow-tooltip="false"
              min-width="320"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="collectionUnit"
              sortable
              min-width="320"
              :show-overflow-tooltip="false"
              label="收款单位"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="sumCost" width="110" sortable label="申请金额" align="center">
              <template slot-scope="scope">
                {{ scope.row.sumCost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="projectBudget"
              width="110"
              sortable
              label="申请时间"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" width="150" sortable prop="statusName" label="流程进度">
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="120">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.status != 0"
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click.stop="handleDetails(scope.row)"
                >
                  详情
                </el-button>
                <el-button
                  v-if="scope.row.status == 0 || scope.row.status == 5"
                  type="text"
                  size="small"
                  class="text_Edit_Bgc"
                  @click.stop="handleEdit(scope.row)"
                >
                  编辑
                </el-button>
                <el-button
                  v-if="scope.row.status == 0"
                  type="text"
                  class="text_Remove_Bgc"
                  size="small"
                  @click.stop="removeAdvance(scope.row.id)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <Edit
      v-if="editShow"
      :isApply="isApply"
      :isShow.sync="editShow"
      :options="form"
      :isSee="isSee"
      :isGrant="isGrant"
      :getTitle="getTitle"
    ></Edit>

    <el-dialog title="外协费用流程" :visible.sync="dialogVisible" width="75%">
      <img src="@/assets/外协费用流程.png" alt="外协费用流程" width="100%" />
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('../dlg/Edit.vue'),
  },
  data() {
    return {
      loading: false, // 表格 加载
      // 查询条件
      parameter: {
        businessManageId: null,
        expenseNumber: null,
        applyType: 'UNDETERMINED',
        pageNow: 1,
        pageSize: 20,
        total: 0, // 表格 -- 数据 总数量
        collectionUnit: null,
      },
      pageSize: 0,
      tableData: [], // 表格数据
      BusinessList: [], // 数据
      editShow: false, // "编辑 -- 组件" 隐藏 或 显示
      isSee: false, // 用于 文本框 是否 禁用
      isApply: false,
      // NumberForm: {},
      dialogVisible: false,
      createdDate: [],
      getTitle: '',
      isGrant: false,
      form: {},
      statistics: {
        undetermind: 0,
        approval: 0,
        approvalReject: 0,
        notPayed: 0,
        approvalPass: 0,
      },
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
    }
  },
  provide() {
    return {
      re: this.closeSubgroup,
    }
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getBusinessList() /** 加载  数据 **/
    this.getTableData() /** 加载 数据 **/
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    'parameter.applyType': {
      // immediate:true,//初始化立即执行
      // deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.parameter.pageNow = 1
          this.getTableData()
        }
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getTableData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  methods: {
    removeAdvance(id) {
      this.$confirm('是否要删除该外协费用申请单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.businessOutsourceCost
            .deleteExpense({ id })
            .then(res => {
              this.$message({
                message: '删除成功!',
                type: 'success',
              })
              this.getTableData()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    /** 加载  数据 **/
    getBusinessList() {
      this.$api.businessOutsourceCost
        .getBusinessNameListByPrincipal()
        .then(res => {
          this.BusinessList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    /** 加载" 数据 **/
    getTableData() {
      this.loading = true
      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = ''
        this.parameter.endDate = ''
      }
      this.$api.businessOutsourceCost
        .getApplyForList(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = res.data ? (res.data.records ? res.data.records : []) : []
          this.parameter.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          this.getApplyStatistics()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    getApplyStatistics() {
      this.$api.businessOutsourceCost
        .getApplyStatistics()
        .then(res => {
          if (res.data) {
            res.data.forEach(v => {
              if (v.applyType == 'UNDETERMINED') {
                this.statistics.undetermind = v.sumQuantity
              } else if (v.applyType == 'APPROVAL') {
                this.statistics.approval = v.sumQuantity
              } else if (v.applyType == 'APPROVAL_REJECT') {
                this.statistics.approvalReject = v.sumQuantity
              } else if (v.applyType == 'NOT_PAYED') {
                this.statistics.notPayed = v.sumQuantity
              } else if (v.applyType == 'APPROVAL_PASS') {
                this.statistics.approvalPass = v.sumQuantity
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleSearch() {
      this.getTableData() /** 加载  数据 **/
    },

    handleReset() {
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.parameter.businessManageId = null
      this.parameter.expenseNumber = null
      this.parameter.collectionUnit = null
      this.createdDate = []
      this.parameter.pageNow = 1
      this.getTableData() /** 加载  数据 **/
    },
    /** 点击 "新增" 按钮 **/
    handleAdd() {
      this.isApply = true
      this.isSee = false
      this.isGrant = false
      this.form = {}
      this.form.list_s = 0
      this.getTitle = '新增外协费用申请单'
      this.editShow = true
    },
    /**
     * 点击 "表格 -- 详情" 操作
     * @param item      当前数据
     */
    handleDetails(item) {
      this.form = item
      if (item.status == 100) {
        // 点击已完成审核的详情
        this.form.list_s = 3
        this.isGrant = true
      } else {
        this.form.list_s = 1
        this.isGrant = false
      }
      if (this.parameter.applyType == 'NOT_PAYED') {
        this.form.export = 1
      }
      this.isApply = false
      this.isSee = true
      this.getTitle = '查看外协费用申请单详情'
      this.editShow = true
    },

    /**
     * 点击 "表格 -- 编辑" 操作
     * @param item      当前数据
     */
    handleEdit(item) {
      this.getTitle = '编辑外协费用申请单'
      this.form = item
      this.isApply = true
      this.isSee = false
      this.editShow = true
    },

    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */

    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getTableData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getTableData()
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }

      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getTableData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /** 关闭 "编辑" 组件 **/
    closeSubgroup() {
      this.getTableData()
      this.editShow = false
    },
  },
  filters: {
    applyAmount(n) {
      return Number(n).toLocaleString()
    },
  },
}
</script>

<style scoped lang="scss">
.mainList .mainList_content .mainList_content_sub .el-table {
  height: calc(100% - 93px);
}

.status {
  margin-bottom: 15px;
}
</style>
